import React, { useState } from "react";
import { Link } from "react-scroll";
import { slide as Menu } from "react-burger-menu";

import MobileLogo from "../../assets/images/boobs-hero.png";

import "./Header.css";

function Header({ isHome }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleStateChange = (state) => {
    setIsOpen(state.isOpen);
  };

  return (
    <div className="Header_Container Header_Absolute">
      <div className="mobile-menu">
        <Menu onStateChange={handleStateChange} isOpen={isOpen} right>
          <Link
            onClick={() => {
              setIsOpen(false);
            }}
            className="menu-item"
            to="story"
            spy={true}
            smooth={true}
          >
            Mission
          </Link>
          <Link
            onClick={() => {
              setIsOpen(false);
            }}
            to="mission"
            spy={true}
            smooth={true}
          >
            Story
          </Link>
          <Link
            onClick={() => {
              setIsOpen(false);
            }}
            to="traits"
            spy={true}
            smooth={true}
          >
            Traits
          </Link>
          <Link
            onClick={() => {
              setIsOpen(false);
            }}
            to="roadmap"
            spy={true}
            smooth={true}
          >
            Roadmap
          </Link>

          <Link
            onClick={() => {
              setIsOpen(false);
            }}
            to="team"
            spy={true}
            smooth={true}
          >
            Team
          </Link>
          <Link
            onClick={() => {
              setIsOpen(false);
            }}
            to="faq"
            spy={true}
            smooth={true}
          >
            FAQ
          </Link>

          <div className="Header_MobileInnerLogo">
            <img alt="inner_logo" height={65} src={MobileLogo} />
          </div>
        </Menu>
      </div>

      <div className="Header">
        <div className="Header_Nav">
          <ul>
            <li>
              <Link to="story" spy={true} smooth={true}>
                Mission
              </Link>
            </li>
            <li>
              <Link to="mission" spy={true} smooth={true}>
                Story
              </Link>
            </li>
            <li>
              <Link to="traits" spy={true} smooth={true}>
                Traits
              </Link>
            </li>
            <li>
              <Link to="roadmap" spy={true} smooth={true}>
                Roadmap
              </Link>
            </li>
            <li>
              <Link to="team" spy={true} smooth={true}>
                Team
              </Link>
            </li>
            <li>
              <Link to="faq" spy={true} smooth={true}>
                FAQ
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
