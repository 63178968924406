import React from "react";

import Discord from "../../assets/images/discord.png";
import Twitter from "../../assets/images/twitter.png";

import "./SocialsSection.css";

function SocialsSection() {
  return (
    <div className="SocialsSection">
      <div className="SocialsSection_Icons">
        <a href="https://discord.gg/booob" target="_blank" rel="noreferrer">
          <img alt="discord" src={Discord} />
        </a>
        <a href="https://twitter.com/booobnft" target="_blank" rel="noreferrer">
          <img alt="twitter" src={Twitter} />
        </a>
      </div>
      <p className="SocialsSection_Rights">© 2021 BOOOB. All right reserved</p>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://etherscan.io/address/0xf7ec00250e16a4c1cc9611d5de8e36b96e088c7c"
        className="SocialsSection_Rights"
      >
        BOOOB Smart Contract
      </a>
    </div>
  );
}

export default SocialsSection;
