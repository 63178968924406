import React from "react";

import PurchaseCard from "../lib/PurchaseCard/PurchaseCard";

import "./TraitsSection.css";

function TraitsSection() {
  return (
    <div id="traits" className="TraitsSection">
      <p className="TraitSection_Title">PURCHASE</p>
      <div className="TraitsSection_Grid">
        {/* <div className="TraitsSection_Purchase">
          <img src={BoobsPurchase} />

          <p>Connect your wallet to see sale stats</p>
          <button>Purchase Soon</button>
        </div> */}
        <PurchaseCard />
        {/* <div className="TraitsSection_Traits">
          <p>Boob Size</p>
          <p>Boob Shape</p>
          <p>Boob Color</p>
          <p>Nipple Trait</p>
          <p>Special Trait</p>
          <p>Background</p>
        </div> */}
      </div>
    </div>
  );
}

export default TraitsSection;
