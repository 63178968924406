import React from "react";

import "./RoadmapSection.css";

function RoadmapSection() {
  return (
    <div id="roadmap" className="RoadmapSection">
      <p className="RoadmapSection_Title">ROADMAP</p>

      <div className="RoadmapSection_Map">
        <div className="RoadmapSection_Entry">
          <p style={{ backgroundColor: "#EED2BA" }}>25%</p>
          <p>
            Launch a signature merch brand to raise awareness and spread the
            Booob brand to over 120 countries.
          </p>
          {/* <img alt="Arrow" src={RoadmapArrow}></img> */}
        </div>

        <div className="RoadmapSection_Entry">
          <p style={{ backgroundColor: "#FF9D9D" }}>50%</p>
          <p>
            Design and develop a Breast Cancer awareness hyper casual game -with
            ad revenue going towards the BOOOB Support Fund. (Game concept
            already developed)
          </p>
          {/* <img alt="Arrow" src={RoadmapArrow}></img> */}
        </div>

        <div className="RoadmapSection_Entry">
          <p style={{ backgroundColor: "#FFDE72" }}>75%</p>
          <p>
            Design and develop a digital lingerie line - for use in the
            metaverse as well as digital content such as AR fashion.
          </p>
          {/* <img alt="Arrow" src={RoadmapArrow}></img> */}
        </div>

        <div className="RoadmapSection_Entry">
          <p>100%</p>
          <p>
            Launch the Booob Support Fund - with it’s own SOPs, affiliations and
            support programs - to help those battling breast cancer.
          </p>
        </div>
      </div>
    </div>
  );
}

export default RoadmapSection;
