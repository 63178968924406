import React, { useContext } from "react";
import { Web3Context } from "../../data/Web3Context/Web3Context";

import "./WalletInfoHeader.css";

function WalletInfoHeader() {
  const { account, connectMetamask } = useContext(Web3Context);

  return (
    <div className={account ? "WalletInfoHeader" : "InfoFooter"}>
      {account ? (
        <p>WALLET CONNECTED [{account}]</p>
      ) : (
        <p
          onClick={() => connectMetamask()}
          className="WalletInfoHeader WalletInfoHeader_Connection"
        >
          WALLET NOT CONNECTED
        </p>
      )}
    </div>
  );
}

export default WalletInfoHeader;
