import React, { useState } from "react";

import "./FAQSection.css";

function FAQEntry({ title, description, bgColor }) {
  const [isOpen, setOpen] = useState(false);

  const toggleFAQ = () => {
    setOpen(!isOpen);
  };

  return (
    <div
      onClick={toggleFAQ}
      className={
        isOpen ? "FAQSection_Entry FAQSection_EntryOpen" : "FAQSection_Entry"
      }
      style={{ backgroundColor: bgColor }}
    >
      <div className="FAQSection_EntryTitle">{title}</div>
      <div className="FAQSection_EntryDescription">{description}</div>
    </div>
  );
}

function FAQSection() {
  return (
    <div id="faq" className="FAQSection">
      <p className="FAQSection_Title">FAQ</p>

      <div className="FAQSection_Entries">
        <FAQEntry
          bgColor={"#FF9D9D"}
          title={"1. What is BOOOB ?"}
          description={
            "BOOOB is a collectable series of 8,008 randomly generated NFTs living on the ethereum blockchain as ERC-721 tokens and hosted on IPFS. "
          }
        />

        <FAQEntry
          bgColor={"#FD87BF"}
          title={"2. How much does a BOOOB cost ?"}
          description={"0.06 ETH"}
        />

        <FAQEntry
          bgColor={"#FFDE72"}
          title={"3. When does BOOOB launch ?"}
          description={"BOOOB sale will start on 25/10/2021, at 1PM EST."}
        />

        <FAQEntry
          bgColor={"#FE6507"}
          title={"4. What is the purpose of BOOOB ?"}
          description={
            "BOOOB aims to provide means for those in need who are dealing with Breast Cancer as well as creating innovative tools to secure awareness in the future - such as our digital lingerie line. "
          }
        />
      </div>
    </div>
  );
}

export default FAQSection;
