import React, { Component } from "react";

import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import MissionSection from "../../components/MissionSection/MissionSection";
import RoadmapSection from "../../components/RoadmapSection/RoadmapSection";
import SocialsSection from "../../components/SocialsSection/SocialsSection";
import StorySection from "../../components/StorySection/StorySection";
import TeamSection from "../../components/TeamSection/TeamSection";
import TraitsSection from "../../components/TraitsSection/TraitsSection";
import WalletInfoHeader from "../../components/WalletInfoHeader/WalletInfoHeader";
import FAQSection from "../../components/FAQSection/FAQSection";

class HomePage extends Component {
  render() {
    return (
      <div>
        <WalletInfoHeader />
        <Header isHome />
        <Hero />
        <StorySection />
        <MissionSection />
        <TraitsSection />
        <RoadmapSection />
        <TeamSection />
        <FAQSection />
        <SocialsSection />
      </div>
    );
  }
}

export default HomePage;
