import React from "react";

import "./MissionSection.css";

function MissionSection() {
  return (
    <div id="mission" className="MissionSection">
      <div className="MissionSection_Elements">
        <div className="MissionSection_Info">
          <p className="MissionSection_Title">STORY</p>
          I have witnessed the emotional and physical challenges breast cancer
          patients go through. <br />
          <br />
          I saw the pain , the willpower , the lost time in hospitals , the hope
          , the fear , the grief , the STRENGTH, the multitasking , the
          uncertainty as well as the relief when results come out clean
          <br />
          <br />
          There is no right and wrong, just a leap of faith and a strength to
          carry on and push through just like everything else in life.
          <br />
          <br />
          The team and I understand what impact a small change can do to women
          out there especially for those in need and we want to be part of that
          change .
          <p className="MissionSection_Footer">
            Maya
            <br />
            <span>
              <a
                href="https://youtu.be/1F370MzWe3Q"
                rel="noreferrer"
                target="_blank"
              >
                Watch the Video
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default MissionSection;
