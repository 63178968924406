import React, { useContext, useState, useRef, useEffect } from "react";
import NumericInput from "react-numeric-input";
import { SkyLightStateless } from "react-skylight";
import Loader from "react-loader-spinner";
import Web3 from "web3";
import BoobsPurchase from "../../../assets/images/boobs-purchase.png";

import { Web3Context } from "../../../data/Web3Context/Web3Context";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./PurchaseCard.css";

function PurchaseCard({ nft, color, border }) {
  const { web3, account, connectMetamask, contract } = useContext(Web3Context);
  const [transactionHash, setTransactionHash] = useState(null);
  const [mintQuantity, setMintQuantity] = useState(5);
  const [transactionConfirmed, setTransactionConfirmed] = useState(false);
  const [currentSupply, setCurrentSupply] = useState(-1);
  const [price, setPrice] = useState(0);
  const [balance, setBalance] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [canMint, setCanMint] = useState(true);
  const [didBegin, setDidBegin] = useState(false);
  const [isPresale, setPresale] = useState(false);

  const numericInput = useRef(null);

  useEffect(() => {
    if (!contract) {
      return;
    }

    getSupply();

    const i = setInterval(async () => {
      getSupply();
    }, 5000);

    const checkSaleInterval = setInterval(async () => {
      checkSaleStatus();
    }, 3000);

    const checkSaleStatus = async () => {
      const { presaleActive } = contract.methods;
      const { mintingActive } = contract.methods;
      const isPresale = await presaleActive().call();
      const isMinting = await mintingActive().call();

      if (isPresale) {
        setDidBegin(true);
        setPresale(true);
      }

      if (isMinting) {
        setDidBegin(true);
        setPresale(false);
        clearInterval(checkSaleInterval);
      }
    };

    var b = null;
    if (account) {
      b = setInterval(async () => {
        try {
          var balance = await web3.eth.getBalance(account);
          const ethBalance = web3.utils.fromWei(balance.toString(), "ether");
          setBalance(parseFloat(ethBalance).toPrecision(2));
        } catch (e) {}
      }, 1000);
    }

    return () => {
      clearInterval(i);
      clearInterval(b);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, contract]);

  const getSupply = async () => {
    try {
      const { totalSupply } = contract.methods;
      const supply = await totalSupply().call();
      setCurrentSupply(supply);
    } catch (e) {}
  };

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const mint = async () => {
    if (!canMint) {
      return;
    }

    setCanMint(false);

    // Reset
    setTransactionHash(null);
    setTransactionConfirmed(false);

    if (mintQuantity <= 0) {
      alert("Please input a valid number !");
      return;
    }

    if (mintQuantity > 25) {
      alert("Maximum mint quantity per transaction is 25 !");
      return;
    }

    const { mintBooob } = contract.methods;

    const price = (parseFloat(0.06) * mintQuantity).toPrecision(4);

    if (balance < price) {
      alert("Insufficient balance ! Please top up your wallet.");
      return;
    }
    setPrice(price);

    const gweiPrice = Web3.utils.toWei(price.toString(), "ether");

    numericInput.current.refsInput.defaultValue = mintQuantity;

    mintBooob(mintQuantity).estimateGas(
      {
        from: account,
        value: gweiPrice.toString(),
      },
      async (err, gas) => {
        if (err) {
          if (err.message.indexOf("Minting not active") > -1) {
            alert("Public minting is not active yet !");
          } else {
            alert("An unknown error has occured !");
          }
          setCanMint(true);
          return;
        }
        numericInput.current.refsInput.defaultValue = mintQuantity;
        mintBooob(mintQuantity).send(
          {
            from: account,
            value: gweiPrice.toString(),
            gas: gas,
          },
          async function (error, txHash) {
            if (error) {
              setCanMint(true);
              return;
            }

            setTransactionHash(txHash);
            setModalOpen(true);

            let transactionReceipt = null;
            while (transactionReceipt == null) {
              transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
              await sleep(1000);
            }

            setCanMint(true);
            setTransactionConfirmed(true);
            setTransactionHash(transactionReceipt.transactionHash);
          }
        );
      }
    );
  };

  const preMint = async () => {
    if (!canMint) {
      return;
    }

    setCanMint(false);

    const fetchRes = await fetch(`https://api.booob.org/proofs/${account}`);
    const data = await fetchRes.json();

    if (!data) {
      alert("An unknown error has occured. Please try again in a few.");
      setCanMint(true);
      return;
    }

    const { idx, proof } = data;

    if (proof === "Not Found" || isNaN(idx)) {
      alert("Unfortunately, you are not on the presale whitelist.");
      setCanMint(true);
      return;
    }

    // Reset
    setTransactionHash(null);
    setTransactionConfirmed(false);

    if (mintQuantity <= 0) {
      alert("Please input a valid number !");
      return;
    }

    if (mintQuantity > 25) {
      alert("Maximum mint quantity per transaction for presale is 25 !");
      return;
    }

    const { mintBooobWhitelist } = contract.methods;

    const price = (parseFloat(0.06) * mintQuantity).toPrecision(4);

    if (balance < price) {
      alert("Insufficient balance ! Please top up your wallet.");
      return;
    }
    setPrice(price);

    const gweiPrice = Web3.utils.toWei(price.toString(), "ether");
    console.log("Price : " + gweiPrice);

    numericInput.current.refsInput.defaultValue = mintQuantity;

    mintBooobWhitelist(mintQuantity, idx, proof).estimateGas(
      {
        from: account,
        value: gweiPrice.toString(),
      },
      async (err, gas) => {
        if (err) {
          console.log(err);
          if (err.message.indexOf("Presale not") > -1) {
            alert("Pre-sale is not active yet !");
          } else if (err.message.indexOf("Not whitelisted") > -1) {
            alert("You are not a whitelisted member !");
          } else if (err.message.indexOf("allowed presale booobs") > -1) {
            alert(
              "You are not allowed to mint this much BOOOB as a whitelisted address !"
            );
          } else {
            alert("An unknown error has occured !");
          }
          setCanMint(true);
          return;
        }
        numericInput.current.refsInput.defaultValue = mintQuantity;
        mintBooobWhitelist(mintQuantity, idx, proof).send(
          {
            from: account,
            value: gweiPrice.toString(),
            gas: gas,
          },
          async function (error, txHash) {
            if (error) {
              setCanMint(true);
              return;
            }

            setTransactionHash(txHash);
            setModalOpen(true);

            let transactionReceipt = null;
            while (transactionReceipt == null) {
              transactionReceipt = await web3.eth.getTransactionReceipt(txHash);
              await sleep(1000);
            }

            setCanMint(true);
            setTransactionConfirmed(true);
            setTransactionHash(transactionReceipt.transactionHash);
          }
        );
      }
    );
  };

  return (
    <div className="PurchaseCard" style={{ borderColor: border }}>
      <img alt="PurchaseIcon" src={BoobsPurchase} />
      <SkyLightStateless
        isVisible={modalOpen}
        onCloseClicked={() => {
          setModalOpen(false);
        }}
      >
        <div className="Modal_Body">
          {!transactionConfirmed ? (
            <div className="Modal_Title">
              <Loader type="TailSpin" color="white" height={50} width={50} />
              <p className="Modal_Status">
                Your transaction is pending ... <br />
                <span>
                  Please wait, or visit the transaction link below for updates
                </span>
              </p>
            </div>
          ) : (
            <div className="Modal_Title">
              <p>Transaction Confirmed !</p>
            </div>
          )}

          <p>SUMMARY</p>
          <div className="separator"></div>
          <div className="Modal_Entries">
            <div className="Modal_Entry">
              <p>TRANSACTION HASH</p>
              <p>
                {transactionHash}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://etherscan.io/tx/${transactionHash}`}
                >
                  MORE INFO...
                </a>
              </p>
            </div>
            <div className="Modal_Entry">
              <p>PRICE</p>
              <p>{price} ETH</p>
            </div>

            <div className="Modal_Entry">
              <p>WALLET</p>
              <p>{account}</p>
            </div>
          </div>
          <div className="separator"></div>

          <div className="Modal_Actions">
            <button
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Dismiss
            </button>
          </div>
          {transactionConfirmed && (
            <div className="Modal_Confirmation">
              Your transaction has confirmed ! You can view your NFT on{" "}
              <a href="https://opensea.io/collection/booob-nft/">Opensea.io</a>{" "}
              //{" "}
              <a
                class="twitter-share-button"
                href="https://twitter.com/intent/tweet?text=Just minted my @booobnft, head over to their website https://booob.org to get yours and support the cause !"
                target="_blank"
                rel="noreferrer"
              >
                Share this on Twitter
              </a>{" "}
              and help us spread the word !
            </div>
          )}
        </div>
      </SkyLightStateless>
      <div>
        <p style={{ color: color }}>
          Buy <span>BOOOB</span> for <span>0.06 ETH</span>
        </p>
      </div>
      {account && didBegin && (
        <NumericInput
          ref={numericInput}
          className="form-control"
          value={mintQuantity}
          onChange={setMintQuantity}
          min={1}
          max={isPresale ? 25 : 25}
          step={1}
          precision={0}
          size={5}
          mobile
        />
      )}
      {account ? (
        balance >= 0.06 && didBegin ? (
          <button
            onClick={() => {
              if (isPresale) {
                preMint();
              } else {
                mint();
              }
            }}
            className="PurchaseCard_ActionButton"
          >
            {isPresale ? "WHITELIST MINT" : "MINT"}
          </button>
        ) : didBegin ? (
          <button className="PurchaseCard_ActionButton">
            INSUFFICIENT FUNDS
          </button>
        ) : (
          <button className="PurchaseCard_ActionButton">
            SALE STILL CLOSED
          </button>
        )
      ) : (
        <button
          onClick={() => connectMetamask()}
          className="PurchaseCard_ActionButton"
        >
          CONNECT WALLET
        </button>
      )}
      {account && (
        <p style={{ color: color, margin: "0", padding: "0" }}>
          Balance: <span>{balance} ETH</span>
        </p>
      )}
      {/* {account && currentSupply !== -1 ? (
        <p style={{ color: color, fontWeight: "bold" }}>
          Minted: <span>{currentSupply}</span>/<span>{8008}</span>
        </p>
      ) : (
        <p style={{ color: color, fontWeight: "bold" }}>
          Minted: <span>-</span>/<span>{8008}</span>
        </p>
      )}{" "} */}
    </div>
  );
}

export default PurchaseCard;
