import React from "react";

import MissionBoobs from "../../assets/images/boobs-mission.png";

import "./StorySection.css";

function StorySection() {
  return (
    <div id="story" className="StorySection">
      <p className="StorySection_Title">MISSION</p>

      <div className="StorySection_Elements">
        <div className="StorySection_Info">
          Our mission is to save lives through awareness and early detection. We
          are a community aiming to equip women and men alike with knowledge as
          well as a platform to share their experiences as both patients and
          secondary patients.
        </div>

        <div className="StorySection_Grid">
          <img alt="Mission_Image" src={MissionBoobs} />
        </div>
      </div>
    </div>
  );
}

export default StorySection;
