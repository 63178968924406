import React from 'react';
import ReactDOM from 'react-dom';
import App from './layout/App';

import { Web3ContextProvider } from './data/Web3Context/Web3Context';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Web3ContextProvider>
      <App />
    </Web3ContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
