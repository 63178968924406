import React, { Component } from "react";

import TeamIcon from "../../assets/images/boobs-teamicon.png";
import Twitter from "../../assets/images/twitter-white.png";

import "./TeamSection.css";

export class TeamSection extends Component {
  render() {
    return (
      <div id="team" className="TeamSection">
        <p className="TeamSection_Title">TEAM</p>

        <div className="TeamSection_Grid">
          <div className="TeamSection_Entry">
            <div className="TeamSection_PF">
              <img alt="TeamIcon" src={TeamIcon} />
            </div>
            <p>MAYA</p>
            <p>
              Art Enthusiast
              <br />
              Fashion Expert{" "}
            </p>
            <a
              href="https://twitter.com/Mayaverse2?s=09"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="twitter" src={Twitter} />
            </a>
          </div>

          <div className="TeamSection_Entry">
            <div className="TeamSection_PF">
              <img alt="TeamIcon" src={TeamIcon} />
            </div>
            <p>Jo</p>
            <p>Social Impact Consultant</p>
            <a
              href="https://twitter.com/joo_light?s=09"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="twitter" src={Twitter} />
            </a>
          </div>

          <div className="TeamSection_Entry">
            <div className="TeamSection_PF">
              <img alt="TeamIcon" src={TeamIcon} />
            </div>
            <p>Alex</p>
            <p>SOP Guru</p>
            <a
              href="https://twitter.com/HabrETH"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="twitter" src={Twitter} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default TeamSection;
