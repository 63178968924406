import React from "react";
import { Link } from "react-scroll";
import HeroBG from "../../assets/images/boobs-hero.png";
import HeroLogo from "../../assets/images/boob-logo.png";

import "./Hero.css";

function Hero() {
  return (
    <div className="Hero">
      <p className="Hero_Subtitle">
        <img
          alt="HeroMob"
          className="Hero_MobileImage"
          src={HeroBG}
          width={300}
        />
        <img alt="HeroLogo" className="Hero_Logo" width={250} src={HeroLogo} />
        <br />
        <br />
        <Link to="traits" spy={true} smooth={true}>
          <button className="Hero_Button">PURCHASE HERE</button>
        </Link>
        <br />
        <br />
        BOOOB is a collectable series of 8,008 randomly generated NFTs living on
        the ethereum blockchain as ERC-721 tokens and hosted on IPFS.
        <br />
        <br />
        Each BOOOB is pretty and unique in its own way. BOOOB is here to listen,
        support women and create a community where hand in hand we can overcome.
        Every BOOOB contains dozens of traits that represent breast cancer
        patients everywhere.
        <br />
        Twenty percent of every minted BOOOB will go to a charity fund to be
        distributed directly to women. To sustain our mission, two percent of
        after sales will also go to the charity wallet.
      </p>
    </div>
  );
}

export default Hero;
